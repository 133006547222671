.image {
    width: 100%;
    height: 100%;
    padding: 50px;
}

.elevation {
    display: flex;
    justify-content: center;
    align-items: center;
}

.image img.img {
    /*width: 100%;*/
    max-width: calc(30vh - 100px);
    /*max-height: 80vh;*/
    box-sizing: border-box;
}

@media screen
and (max-width: 768px) {
    .image,
    .elevation {
        padding: 10px;
    }
    .image img.img {
        /*max-width: calc(100% - 60px);*/
        height: auto;
    }
}

@media only screen
and (min-device-width: 300px)
and (max-device-width: 1000px)
and (orientation: landscape) {
    .image,
    .elevation {
        padding: 10px;
    }
    .image img.img {
        max-width: calc(100% - 60px);
        height: auto;
    }
}