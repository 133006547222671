.list ul {
    list-style: none;
}

.list ul li::before {
    content: "\2022";
    color: #F9AF6B;
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
}

.list p,
.list ul li {
    font-size: 24px;
    line-height: 36px;
    color: #5C5C5C;
}

.list.horizontal p,
.list.horizontal ul li {
    font-size: 16px;
    line-height: 26px;
}

@media screen
and (max-width: 768px) {
    .list p,
    .list ul li,
    .list.horizontal p,
    .list.horizontal ul li {
        font-weight: 300;
        font-size: 12px;
        line-height: 14px;
    }
}

@media only screen
and (min-device-width: 300px)
and (max-device-width: 1000px)
and (orientation: landscape) {
    .list p,
    .list ul li,
    .list.horizontal p,
    .list.horizontal ul li {
        font-weight: 300;
        font-size: 12px;
        line-height: 14px;
    }
}
