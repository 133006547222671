.description {
    padding: 50px;
    background: #FFFFFF;
    box-shadow: none;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
}

.description.horizontal {
    padding: 50px;
    background: #F8F8F8;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
    height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
}

.paragraph.horizontal {
    font-weight: 300;
    font-size: 16px;
    line-height: 26px;
    color: #5C5C5C;
}

.paragraph {
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
    color: #5C5C5C;
}

@media screen
and (max-width: 768px) {
    .description,
    .description.horizontal {
        padding: 20px;
    }
    .paragraph.horizontal,
    .paragraph {
        font-weight: 300;
        font-size: 12px;
        line-height: 14px;
    }
}

@media only screen
and (min-device-width: 300px)
and (max-device-width: 1000px)
and (orientation: landscape) {
    .description,
    .description.horizontal {
        padding: 20px;
    }
    .paragraph.horizontal,
    .paragraph {
        font-weight: 300;
        font-size: 12px;
        line-height: 14px;
    }
}