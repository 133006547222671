.price {
    background-color: #F9AF6B;
    padding: 50px;
    position: absolute;
    top: 0;
    left: 25%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #FFFFFF;
    z-index: 1;
}

.price > img {
    margin-right: 50px;
}

.price > span {
    font-weight: bold;
    font-size: 26px;
    line-height: 30px;
}

@media only screen and (max-width: 1080px) {
    .price {
        left: 40%;
    }
}

@media only screen and (max-width: 768px) {
    .price {
        left: 50%;
        transform: translateX(-50%);
        padding: 20px;
    }
    .price > img {
        margin-right: 20px;
    }
    .price > span {
        font-weight: bold;
        font-size: 20px;
        line-height: 20px;
    }
}

@media only screen and (max-width: 420px) {
    .price {
        left: 50%;
        transform: translateX(-50%);
        padding: 10px;
    }
    .price > img {
        margin-right: 10px;
    }
    .price > span {
        font-weight: bold;
        font-size: 14px;
        line-height: 14px;
    }
}

@media only screen
and (min-device-width: 300px)
and (max-device-width: 1000px)
and (orientation: landscape) {
    .price {
        left: 45%;
        transform: translateX(-45%);
        padding: 10px;
    }
    .price > img {
        margin-right: 10px;
        max-height: 40px;
    }
    .price > span {
        font-weight: bold;
        font-size: 14px;
        line-height: 14px;
    }
}
