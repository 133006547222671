.mdc-tab {
    flex-grow: 0 !important;
}

.mdc-tab__content {
    font-size: 22px;
    line-height: 30px;
}

@media screen
and (max-width: 1080px) {
    .mdc-tab__content {
        font-size: 26px;
        line-height: 30px;
    }
}

@media screen
and (max-width: 768px) {
    .mdc-tab {
        height: 24px;
    }

    .mdc-tab__content {
        font-size: 12px;
        line-height: 14px;
    }
}

@media only screen
and (min-device-width: 300px)
and (max-device-width: 1000px)
and (orientation: landscape) {
    .mdc-tab {
        height: 24px;
    }

    .mdc-tab__content.horizontal {
        font-size: 12px;
        line-height: 14px;
    }

    .mdc-tab__content {
        font-size: 12px;
        line-height: 14px;
    }
}