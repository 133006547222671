.product {
    position: relative;
    /*overflow: hidden;*/
    width: 100vw;
    height: 100vh;
    margin: 0;
    padding: 0;
}

.product.grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
}

.product.grid > section:nth-child(1) {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
}

.product.grid > section:nth-child(2) {
    grid-column: 2 / 3;
    grid-row: 1 / 2;
}

.product.grid > section:nth-child(3) {
    grid-column: 1 / 3;
    grid-row: 2 / 3;
    overflow-y: hidden;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.product.grid > section:nth-child(3)::-webkit-scrollbar {
    display: none;
}

.product.flex {
    display: flex;
}

.product.flex > section:nth-child(1),
.product.flex > section:nth-child(2) {
    flex: 0 0 30vw;
}

.product.flex > section:nth-child(3) {
    flex: 0 0 40vw;
}