.props {
    box-sizing: border-box;
    padding: 175px 50px 0 50px;
}

.props.horizontal > div:nth-child(1) {
    font-weight: normal;
    font-size: 20px;
    line-height: 23px;
    color: #B5B5B5;
    margin-bottom: 30px;
}

.props.horizontal > div:nth-child(2) {
    font-size: 26px;
    line-height: 26px;
    color: #000000;
    margin-bottom: 30px;
}

.props.horizontal > div:nth-child(3) > div {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-bottom: 13px;
}

.props.horizontal > div:nth-child(3) > div > div:nth-child(1) {
    flex-basis: 50%;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #000000;
    text-transform: uppercase;
}

.props.horizontal > div:nth-child(3) > div > div:nth-child(2) {
    font-size: 16px;
    line-height: 18px;
    text-align: right;
    color: #5C5C5C;
}

.props > div:nth-child(1) {
    font-weight: normal;
    font-size: 24px;
    line-height: 28px;
    color: #B5B5B5;
    margin-bottom: 50px;
}

.props > div:nth-child(2) {
    font-weight: normal;
    font-size: 38px;
    line-height: 38px;
    color: #000000;
    margin-bottom: 50px;
}

.props > div:nth-child(3) > div {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-bottom: 30px;
}

.props > div:nth-child(3) > div > div:nth-child(1) {
    flex-basis: 50%;
    font-weight: 500;
    font-size: 26px;
    line-height: 30px;
    color: #000000;
    text-transform: uppercase;
}

.props > div:nth-child(3) > div > div:nth-child(2) {
    font-size: 24px;
    line-height: 30px;
    text-align: right;
    color: #5C5C5C;
}

@media only screen
and (max-width: 768px) {
    .props {
        padding: 100px 20px 0 0;
    }
    .props > div:nth-child(1),
    .props.horizontal > div:nth-child(1) {
        font-size: 12px;
        line-height: 12px;
        margin-bottom: 8px;
    }
    .props > div:nth-child(2),
    .props.horizontal > div:nth-child(2) {
        font-size: 16px;
        line-height: 16px;
        margin-bottom: 8px;
    }
    .props > div:nth-child(3) > div,
    .props.horizontal > div:nth-child(3) > div {
        margin-bottom: 8px;
    }
    .props > div:nth-child(3) > div > div:nth-child(1),
    .props.horizontal > div:nth-child(3) > div > div:nth-child(1) {
        font-size: 12px;
        line-height: 12px;
    }
    .props > div:nth-child(3) > div > div:nth-child(2),
    .props.horizontal > div:nth-child(3) > div > div:nth-child(2) {
        font-size: 10px;
        line-height: 12px;
    }
}

@media only screen
and (max-width: 420px) {
    .props {
        padding: 75px 20px 0 0;
        overflow: hidden;
    }
}

@media only screen
and (min-device-width: 300px)
and (max-device-width: 1000px)
and (orientation: landscape) {
    .props {
        width: 100%;
        /*height: 100%;*/
        box-sizing: border-box;
        padding: 75px 20px 0 0;
        overflow: hidden;
    }
    .props > div:nth-child(1),
    .props.horizontal > div:nth-child(1) {
        font-size: 12px;
        line-height: 12px;
        color: #B5B5B5;
        margin-bottom: 8px;
    }
    .props > div:nth-child(2),
    .props.horizontal > div:nth-child(2) {
        font-size: 16px;
        line-height: 16px;
        color: #000000;
        margin-bottom: 8px;
    }
    .props > div:nth-child(3) > div,
    .props.horizontal > div:nth-child(3) > div {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        margin-bottom: 8px;
    }
    .props > div:nth-child(3) > div > div:nth-child(1),
    .props.horizontal > div:nth-child(3) > div > div:nth-child(1) {
        flex-basis: 50%;
        font-weight: 500;
        font-size: 12px;
        line-height: 12px;
    }
    .props > div:nth-child(3) > div > div:nth-child(2),
    .props.horizontal > div:nth-child(3) > div > div:nth-child(2) {
        font-size: 10px;
        line-height: 12px;
    }
}
