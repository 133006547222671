.home {
    position: relative;
    width: 100vw;
    height: 100vh;
    z-index: 10;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
    opacity: 0.05;
    transition: all 500ms ease-in-out;
}

.wrapper.video {
    z-index: 1;
    opacity: 1;
}

.wrapper video {
    height: 100%;
    object-fit: cover;
}

.home section {
    max-width: 80vw;
    margin: 0 auto;
    text-align: center;
    position: relative;
    z-index: 0;
}

.home section img {
    max-width: 100%;
    height: auto;
    margin: 16px auto;
}

.home section p {
    font-size: 24px;
    line-height: 32px;
    color: #828282;
    margin: 0;
}

.search {
    margin: 16px 0;
}

.search :global(.mdc-text-field__input) {
    padding: 16px !important;
    font-size: 24px;
    line-height: 24px;
    border: 3px solid #DEDEDE;
    box-sizing: border-box;
    border-radius: 28px;
    background-color: #FFFFFF;
}

.search :global(.mdc-line-ripple) {
    display: none !important;
}

@media only screen
and (min-device-width: 300px)
and (max-device-width: 1000px)
and (orientation: landscape) {
    .home section p {
        font-size: 12px;
        line-height: 14px;
    }
    .home section img {
        max-width: 200px;
        height: auto;
        margin: 16px auto;
    }
}