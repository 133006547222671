.close {
    position: absolute;
    z-index: 100;
    cursor: pointer;
    top: 0;
    right: 0;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    background: #D68D8D;
    box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.1);
}

.close div:nth-child(1) {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) rotate(135deg);
    width: 46px;
    height: 6px;
    background: #FFFFFF;
    border-radius: 10px;
}

.close div:nth-child(2) {
    position: absolute;
    width: 46px;
    height: 6px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    background: #FFFFFF;
    border-radius: 10px;
}