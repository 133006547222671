.button {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 60px;
    height: 60px;
    left: 30px;
    top: 30px;
    border-radius: 50%;
    background: #E9E9E9;
    z-index: 1;
}

.button:hover {
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

@media screen
and (max-width: 768px) {
    .button {
        top: 10px;
        left: 10px;
    }
}

@media only screen
and (min-device-width: 300px)
and (max-device-width: 1000px)
and (orientation: landscape) {
    .button {
        top: 10px;
        left: 10px;
    }
}